.container {
  @apply flex items-start mb-8 relative w-full;

  &:not(.small) {
    @screen lg {
      @apply flex-col mb-0 w-2/12;
    }
  }

  &.small:after {
    @apply absolute bottom-0 left-0 -mx-4 my-0 right-0 top-auto h-1px w-auto;
    background-color: rgba(255, 255, 255, .1);
    content: '';
  }

  &:after {
    @apply absolute bottom-0 left-0 -mx-4 my-0 right-0 top-auto h-1px w-auto;
    background-color: rgba(255, 255, 255, .1);
    content: '';
  
    @screen lg {
      @apply absolute bottom-0 left-auto mx-0 -my-16 right-0 top-0 h-48 w-1px;
      background-color: rgba(255, 255, 255, .1);
      content: '';
    }
  }
}

.button {
  @apply duration-300 px-2 py-4 rounded-t-lg text-sm text-white transition-colors w-full;

  &:not(.small) {
    @screen lg {
      @apply py-2 rounded-l-md rounded-t-none text-left;
    }
  }

  &:focus {
    @apply outline-none;
  }
}

.selected {
  @apply font-bold border-b border-white;
  background-color: rgba(255, 255, 255, .1);

  &:not(.small) {
    @screen lg {
      @apply border-b-0 border-r border-white;
    }
  }
}